import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";

@Component({
  selector: "app-add-unit",
  templateUrl: "./add-unit.component.html",
  styleUrls: ["./add-unit.component.scss"]
})
export class AddUnitComponent implements OnInit {
  constructor(private apiService: ApiService) { }
  countdown: number;
  verificationInProgress = false;
  unit = {
    unitname: "",
    imei: ""
  };
  verificationFailed: boolean = false;
  ngOnInit() { }

  startVerification(fo) {
    if (fo.valid) {
      this.verificationInProgress = true;
      this.verificationFailed = false;
      this.countdown = 120;

      var requested_date = Math.round(new Date().getTime() / 1000);

      this.verifyDevice(this.unit.unitname, this.unit.imei, requested_date);
      var timer = setInterval(() => {
        this.countdown = --this.countdown;
        if (this.countdown <= 0) {
          this.verificationFailed = true;
          clearInterval(timer);
        }
      }, 1000);
    }
  }

  retry() {
    this.verificationInProgress = false;
  }

  verifyDevice(unitname, unit_imei, requested_time) {
    var postData = {
      unitname: unitname,
      unit_imei: unit_imei,
      requested_time: requested_time
    };
    this.apiService.verifyDevice(postData).subscribe(data => {
      if (data == "success") {
        this.apiService.toastr.success(
          "Successfully verified! Unit added to the profile."
        );
        this.verificationInProgress = false;
        this.verificationFailed = false;
      } else if (data == "exists") {
        this.apiService.toastr.error(
          "IMEI already associated with someother user profile"
        );
      } else {
        if (this.verificationInProgress) {
          setTimeout(() => {
            this.verifyDevice(unitname, unit_imei, requested_time);
          }, 1000);
        }
      }
    });
  }

  getCountDown() {
    return (
      Math.floor(this.countdown / 60) +
      ":" +
      (this.countdown - Math.floor(this.countdown / 60) * 60)
    );
  }
}
