import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../api.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  constructor(private apiService: ApiService) {}

  fileList: FileList;
  fileChange(event) {
    this.fileList = event.target.files;
  }

  profile = {
    user: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    orgName: "",
    password: "",
    retypepassword: ""
  };

  ngOnInit() {}

  validate(fo) {
    if (fo.valid) {
      var formD = new FormData();
      if (this.fileList.length > 0) {
        let file: File = this.fileList[0];
        console.log(file);

        formD.append("uploadFile", file, file.name);
      }
      formD.append("data", JSON.stringify(this.profile));

      this.apiService.registerProfile(formD).subscribe(data => {
        if (data == "success") {
          this.apiService.messageDialog(
            "Profile created! Kindly login now. You will be redirected to home page..."
          );
          setTimeout(function() {
            location.href = "/";
          }, 3000);
        } else if (data == "alreadyexists") {
          this.apiService.toastr.error(
            "Already user with this email exists! Want to login?",
            "Error!"
          );
        } else if (data == "invalidimage") {
          this.apiService.toastr.error(
            "Invalid image! Choose different image.",
            "Error!"
          );
        } else {
          this.apiService.toastr.error(
            "Error registering user! Please try after sometime",
            "Error!"
          );
        }
      });
    } else {
      this.apiService.toastr.error("Check your inputs!");
    }
  }
}
