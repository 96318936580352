import { Injectable } from "@angular/core";
import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CookieService } from "../../node_modules/ngx-cookie-service";
import { ToastrService } from "../../node_modules/ngx-toastr";

export interface MessageData {
  message: "";
}
@Component({
  selector: "message-example",
  templateUrl: "message.html"
})
export class MessageDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageData) { }
}

@Injectable({
  providedIn: "root"
})
export class ApiService {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public cookie: CookieService,
    public toastr: ToastrService
  ) { }

  isLoggedIn(): Boolean {
    if (this.cookie.get("userData")) {
      return true;
    } else {
      return false;
    }
  }

  setLogin(userData) {
    this.cookie.set("userData", userData);
  }

  unsetLogin() {
    this.cookie.delete("userData");
  }

  messageDialog(message) {
    this.dialog.open(MessageDialog, {
      width: "350px",
      data: {
        message: message
      }
    });
  }

  login(us: string, ps: string): Observable<any> {
    const body = { username: us, password: ps };
    const h: Object = { responseType: "text" };
    return this.http.post<any>("http://oceanlink.io/backend/login.php", body, h);
  }

  logout(): Observable<any> {
    const h: Object = { responseType: "text" };
    return this.http.get<any>("http://oceanlink.io/backend/logout.php", h);
  }

  resetpass(email) {
    const body = { email: email };
    const h: Object = { responseType: "text" };
    return this.http.post<any>(
      "http://oceanlink.io/backend/resetpass.php",
      body,
      h
    );
  }

  changepassword(oldpass, newpass): Observable<any> {
    const h: Object = { responseType: "text" };

    return this.http.post<any>(
      "http://oceanlink.io/backend/changepassword.php",
      {
        oldpass: oldpass,
        newpass: newpass,
        userid: this.cookie.get("userData")
      },
      h
    );
  }

  getProfile(id = this.cookie.get("userData")): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Accept", "application/json");

    return this.http.post<any>(
      "http://oceanlink.io/backend/getProfile.php",
      {
        userid: id
      },
      { headers: headers }
    );
  }

  updateProfile(postData: FormData): Observable<any> {
    const h: Object = { responseType: "text" };
    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    headers.append("response-type", "text");
    return this.http.post<any>(
      "http://oceanlink.io/backend/updateProfile.php",
      postData,
      {
        headers: headers
      }
    );
  }

  registerProfile(postData: FormData): Observable<any> {
    const h: Object = { responseType: "text" };
    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    headers.append("Response-Type", "text");
    return this.http.post<any>(
      "http://oceanlink.io/backend/register.php",
      postData,
      {
        headers: headers,
        responseType: "text" as "json"
      }
    );
  }

  verifyDevice(postData): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Accept", "application/json");
    return this.http.post<any>(
      "http://oceanlink.io/backend/verifyDevice.php",
      { userid: this.cookie.get("userData"), ...postData },
      {
        headers: headers,
        responseType: "text" as "json"
      }
    );
  }

  getUnitData(unitimei, filters): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Accept", "application/json");

    return this.http.post<any>(
      "http://oceanlink.io/backend/getUnitData.php",
      {
        unitimei: unitimei,
        filters: filters
      },
      { headers: headers }
    );
  }
}
