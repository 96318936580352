import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";

@Component({
  selector: "app-forgotpass",
  templateUrl: "./forgotpass.component.html",
  styleUrls: ["./forgotpass.component.scss"]
})
export class ForgotpassComponent implements OnInit {
  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  reset(v, email) {
    this.apiService.resetpass(email.value).subscribe(
      data => {
        console.log(data);
        if (data == "success") {
          this.apiService.messageDialog(
            "Password sent to your email address if exists!"
          );
        } else {
          this.apiService.messageDialog(
            "Error sending password reset instructions"
          );
        }
      },
      error => {
        console.log(error);
        if (error.status == "400") {
          this.apiService.messageDialog("Invalid email");
        } else {
          this.apiService.messageDialog("Error logging in. Try after sometime");
        }
      }
    );
  }
}
