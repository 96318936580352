import { Component, Inject } from "@angular/core";
import { ApiService } from "./api.service";
import { Router } from "@angular/router";
import {
  MAT_DIALOG_DATA,
  MatDialog
} from "../../node_modules/@angular/material/dialog";

export interface AddUnitData {
  addunit: {};
}
@Component({
  selector: "add-unit",
  template: "<app-add-unit></app-add-unit>"
})
export class AddUnitDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddUnitData,
    private apiService: ApiService
  ) { }
}

export interface ProfileData {
  Profile: {};
}
@Component({
  selector: "add-unit",
  template: "<app-authorprofile></app-authorprofile>"
})
export class ProfileDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProfileData,
    private apiService: ApiService
  ) { }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  constructor(
    @Inject(ApiService) private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog
  ) { }
  title = "OceanLink";
  logged_items = this.apiService.isLoggedIn();
  selectedmenu = 0;
  forgetScreen = false;
  logged_in_menu: object[] = [];

  openAddUnitDialog() {
    this.dialog.open(AddUnitDialog, { width: "600px", data: {} });
  }

  openProfileDialog() {
    this.dialog.open(ProfileDialog, { width: "800px", data: {} });
  }

  ishome() {
    return this.router.url.split("/")[1] == "";
  }

  forgetScreenToggle() {
    this.forgetScreen = !this.forgetScreen;
  }
  logout() {
    this.apiService.logout().subscribe(
      data => {
        console.log(data);
        if (data == "success") {
          this.apiService.unsetLogin();
          this.apiService.messageDialog(
            "Successfully logged out! Redirecting to home ..."
          );
          setTimeout(function () {
            location.href = "/";
          }, 3000);
        } else {
          this.apiService.messageDialog("Error! Try after sometime.");
        }
      },
      error => {
        this.apiService.messageDialog("Error! Try after sometime.");
      }
    );
  }

  selectMenu(index: number) {
    console.log(index);
    this.selectedmenu = index;
  }
}
