import { Component, OnInit } from "@angular/core";
import { ReplaySubject } from "../../../node_modules/rxjs";
import { FormControl } from "../../../node_modules/@angular/forms";
import { ApiService } from '../api.service';
import "leaflet-polylinedecorator"

declare let L;

interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  constructor(private apiService: ApiService) { }

  devices = [
    ""
  ];

  devicedata = [];
  lineCoordinate = [];
  filters = {};

  selectedDeviceData: number = 0;

  selectionChanged($event) {

    this.apiService.getUnitData($event.value.imei, this.filters).subscribe(data => {

      this.devicedata = data
      this.devicedata.map((obj) => {
        var date = new Date(parseInt(obj.recorded_time) * 1000);
        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = "0" + date.getMinutes();
        var seconds = "0" + date.getSeconds();
        obj.recorded_time = day + "-" + month + "-" + year + " " + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return obj;
      });

      this.setData(data, 0)
    });
  }

  selectedDataChanged(index) {
    this.selectedDeviceData = index
    this.setData(this.devicedata, index)
  }

  dropconfig = {
    displayKey: "unitname", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "400px", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Choose device", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No devices found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search devices", // label thats displayed in search input,
    searchOnKey: "name" // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  get options() {
    return [...this.devices];
  }

  get config() {
    return { ...this.dropconfig };
  }


  ngOnInit() {
    this.apiService.getProfile().subscribe((data) => {
      this.devices = data.units_attached
      console.log(this.devices)
    })


    this.setPoints(undefined, [13.8344762, 80.5616219])
  }

  setData(data, pointIndex) {

    var pointData = {
      "type": "FeatureCollection",
      "features": [

      ]
    };


    this.lineCoordinate = [];


    for (let index = 0; index < data.length; index++) {
      console.log(data[index].location);
      if (data[index].location != "") {
        var arr = data[index].location.split(",");
        arr.map((elem) => { return parseInt(elem) })
        pointData.features.push({
          "geometry": {
            "type": "Point",
            "coordinates": [
              arr[1], arr[0]
            ]
          },
          "type": "Feature",
          "properties": {
            "popupContent": "<div>Index: " + (data.length - index).toString() + ".</div> <div>" + data[index].location + "</div><div>" + "<i class='fa fa-clock-o'></i>" + data[index].recorded_time + "</div>"
          },
          "id": index
        });
        this.lineCoordinate.push([arr[0], arr[1]]);
      }
    }

    this.setPoints(pointData, [pointData.features[pointIndex].geometry.coordinates[1], pointData.features[pointIndex].geometry.coordinates[0]])

  }


  onEachFeature(feature, layer) {
    var popupContent = "";
    if (feature.properties && feature.properties.popupContent) {
      popupContent += feature.properties.popupContent;
    }
    layer.bindPopup(popupContent);
  }

  setPoints(points = undefined, view) {
    if (map != undefined) {
      map.off();
      map.remove();
    }
    document.getElementById('mapview').innerHTML = '<div id="map" style="position: absolute; z-index: 1; width: 100%; height: 100%;"></div>';

    var map = L.map('map').setView(view, 10);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(map);

    if (points != undefined) {

      var polyline = L.polyline(this.lineCoordinate.reverse(), { color: "red" }).addTo(map);

      L.geoJSON([points], {
        style: (feature) => {
          return feature.properties && feature.properties.style;
        },
        onEachFeature: this.onEachFeature,
        pointToLayer: (feature, latlng) => {
          return L.circleMarker(latlng, {
            radius: this.selectedDeviceData == feature.id ? 10 : 6,
            fillColor: this.selectedDeviceData == feature.id ? "#49b970" : "red",
            color: this.selectedDeviceData == feature.id ? "#49b970" : "red",
            weight: 1,
            opacity: 1,
            fillOpacity: 1
          });
        }
      }).addTo(map);






      L.polylineDecorator(polyline, {
        patterns: [
          { offset: 25, repeat: 50, symbol: L.Symbol.arrowHead({ pixelSize: 15, pathOptions: { fillOpacity: 1, weight: 0, color: "red" } }) }
        ]
      }).addTo(map);

    }
  }

  connectTheDots(data) {
    var c = [];
    for (var i in data._layers) {
      var x = data._layers[i]._latlng.lat;
      var y = data._layers[i]._latlng.lng;
      c.push([x, y]);
    }
    return c;
  }


}
