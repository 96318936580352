import { Component, OnInit, Inject, Injectable } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";

export interface ChangePasswordData {
  animal: "panda" | "unicorn" | "lion";
}
@Component({
  selector: "change-password",
  templateUrl: "changepassword.html"
})
export class ChangePasswordDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangePasswordData,
    private apiService: ApiService
  ) {}
  password: string;
  oldpassword: string;
  retypepassword: string;
  successchanged = false;
  failurechanged = false;

  changepassword() {
    this.apiService
      .changepassword(this.oldpassword, this.password)
      .subscribe(data => {
        if (data == "success") {
          this.successchanged = true;
          this.failurechanged = false;
        } else {
          this.failurechanged = true;
          this.successchanged = false;
        }
      });
  }
}

@Component({
  selector: "app-authorprofile",
  templateUrl: "./authorprofile.component.html",
  styleUrls: ["./authorprofile.component.scss"]
})
export class AuthorprofileComponent implements OnInit {
  constructor(private dialog: MatDialog, private apiService: ApiService) {}

  profileEditMode: boolean = false;
  listed_units: string[];

  profile = {
    userid: "",
    firstname: "",
    lastname: "",
    orgName: "",
    email: "",
    phone: "",
    profile_pic: "",
    units_attached: []
  };
  fileList: FileList;
  fileChange(event) {
    this.fileList = event.target.files;
  }
  profileEditModeToggle() {
    this.profileEditMode = !this.profileEditMode;
  }

  updateProfile(fo) {
    if (fo.valid) {
      var formData: FormData = new FormData();
      formData.append("data", JSON.stringify(this.profile));
      if (this.fileList) {
        if (this.fileList.length > 0) {
          let file: File = this.fileList[0];
          formData.append("uploadFile", file, file.name);
        }
      }

      this.apiService.updateProfile(formData).subscribe(data => {
        if (data == "success") {
          this.listed_units = [...this.profile.units_attached];
          this.apiService.toastr.success(
            "Success!",
            "Profile updated successfully!"
          );
        } else if (data == "invalidimage") {
          this.apiService.toastr.error(
            "Invalid image! Choose different image.",
            "Error!"
          );
        } else {
          this.apiService.toastr.error(
            "Error!",
            "Error updating profile! Please try after sometime"
          );
        }
      });
    } else {
      this.apiService.toastr.error("Check your inputs!");
    }
    this.ngOnInit();
  }

  ngOnInit() {
    this.apiService.getProfile().subscribe(data => {
      this.profile = data;
    });
    this.profileEditMode = false;
  }
  openChangePasswordDialog() {
    this.dialog.open(ChangePasswordDialog, { data: {} });
  }

  detachUnit(index) {
    this.profile.units_attached.splice(index, 1);
  }
}
