import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, AddUnitDialog, ProfileDialog } from "./app.component";

import { LoginComponent } from "./login/login.component";

import { HomeComponent } from "./home/home.component";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectDropDownModule } from "ngx-select-dropdown";

import { MessageDialog, ApiService } from "./api.service";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { HttpClientModule } from "@angular/common/http";
import { CookieService } from "../../node_modules/ngx-cookie-service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatFormFieldModule, MatSelectModule } from "@angular/material";
import { RegisterComponent } from "./register/register.component";
import { ToastrModule } from "ngx-toastr";
import { ForgotpassComponent } from "./forgotpass/forgotpass.component";
import { AboutComponent } from "./about/about.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AddUnitComponent } from "./add-unit/add-unit.component";
import {
  AuthorprofileComponent,
  ChangePasswordDialog
} from "./authorprofile/authorprofile.component";

@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    HomeComponent,
    MessageDialog,
    RegisterComponent,

    AddUnitDialog,
    ForgotpassComponent,

    AboutComponent,

    AddUnitComponent,
    ChangePasswordDialog,
    AuthorprofileComponent,
    ProfileDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    SelectDropDownModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    ApiService,
    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MessageDialog,
    AddUnitDialog,
    ChangePasswordDialog,
    ProfileDialog
  ]
})
export class AppModule {}
