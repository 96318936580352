import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  emailValue: string;
  passwordValue: string;
  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  validate(f, us, ps) {
    if (f.valid) {
      this.apiService.login(us.value, ps.value).subscribe(
        data => {
          console.log(data);
          if (data == "invalid") {
            this.apiService.toastr.error("Invalid login credentials");
          } else if (data == "empty") {
            this.apiService.toastr.error("Invalid login credentials");
          } else if (data == "error") {
            this.apiService.toastr.error("Error validating user. Try later.");
          } else {
            this.apiService.setLogin(data);
            location.href = "/";
          }
        },
        error => {
          console.log(error);
          if (error.status == "400") {
            this.apiService.messageDialog("Invalid login credentials");
          } else {
            this.apiService.messageDialog(
              "Error logging in. Try after sometime"
            );
          }
        }
      );
    } else {
    }
  }
}
